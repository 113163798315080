/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import styled from 'styled-components'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Halls from 'components/Halls'
import MoreInformation from 'components/MoreInformation'
import CallToAction from 'components/CallToAction'
import GravityForm from 'components/GravityForm'

// Elements
import ContentDefault from 'components/elements/ContentDefault'
import HeroDefault from 'components/elements/HeroDefault'
import MoodImage from 'components/elements/MoodImage'
import TitleSub from 'components/elements/TitleSub'
import BrandTitle from 'components/elements/BrandTitle'
import ButtonDefault from 'components/elements/ButtonDefault'

const Form = styled.div`
  background-color: ${props => props.theme.color.text.secondary};
  border-radius: 15px;

  & h3 {
    color: #fff;
    font-family: atrament-web, Arial;
    font-size: 45px;
    text-transform: uppercase;

    @media (min-width: 992px) {
      margin-bottom: -1rem;
    }

    @media (max-width: 991px) {
      font-size: 25px;
    }
  }
`

const Content = styled(ContentDefault)`
  & img {
    width: 85%;
    height: auto;
    object-fit: cover;
  }
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      content
      acf {
        backgroundImage: banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              meta: fluid(maxWidth: 800, toFormat: JPG) {
                src
              }
            }
          }
        }
        contactRelation: contact_relation
        ctaRelation: cta_relation
        moodImage: view_mood_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: {
      title,
      content,
      path,
      yoastMeta,
      acf: { backgroundImage, moodImage, contactRelation },
    },
  },
}) => {
  return (
    <Layout headerTop>
      <SEO
        yoast={{ meta: yoastMeta }}
        pathname={path}
        image={backgroundImage.localFile.childImageSharp.meta.src}
        article
      />
      <HeroDefault title={title} image={backgroundImage} />
      <section>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-7 pl-lg-5">
              <div className="mt-5">
                <TitleSub>{parse(title)}</TitleSub>
                <BrandTitle />
                <Content content={content} />
              </div>
            </div>
            <div className="col-lg-5 mt-5 mt-lg-0">
              <Form>
                <h3 className="px-3 px-lg-5 pt-3 pt-lg-5">Aanmelden</h3>
                <GravityForm id={2} />
              </Form>
            </div>
          </div>
        </div>
      </section>

      {contactRelation && (
        <section>
          <MoreInformation id={contactRelation} />
        </section>
      )}

      <div className="container pb-5">
        <Halls className="mt-5" />
      </div>

      {moodImage && <MoodImage image={moodImage} />}
    </Layout>
  )
}

export default PageTemplate
